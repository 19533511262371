<template>
  <section class="invoice-add-wrapper">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="invoiceData === undefined">
      <h4 class="alert-heading">Erreur de recupération des données</h4>
      <div class="alert-body">
        Pas de facture trouvé avec ce ID. Vérfier
        <b-link class="alert-link" :to="{ name: 'apps-invoice-list' }">
          La liste des factures
        </b-link>
        pour d'autres factures.
      </div>
    </b-alert>

    <b-row v-if="invoiceData" class="invoice-add">
      <!-- Col: Left (Invoice Container) -->
      <b-col cols="12" xl="9" md="8">
        <b-form @submit.prevent>
          <b-card no-body class="invoice-preview-card">
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">
              <div
                class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
              >
                <!-- Header: Left Content -->
                <invoice-details />

                <!-- Header: Right Content -->
                <div class="invoice-number-date mt-md-0 mt-2">
                  <div
                    class="d-flex align-items-center justify-content-md-end mb-1"
                  >
                    <h4 class="invoice-title">Facture</h4>
                    <b-input-group
                      class="input-group-merge invoice-edit-input-group disabled"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="HashIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="invoice-data-id"
                        v-model="invoiceData.facture_no"
                        disabled
                      />
                    </b-input-group>
                  </div>
                  <div class="d-flex align-items-center mb-1">
                    <span class="title"> Date: </span>
                    <flat-pickr
                      :config="flatPickrConfig"
                      v-model="invoiceData.issued_date"
                      class="form-control invoice-edit-input"
                      placeholder="Entrer la date"
                    />
                  </div>
                  <div class="d-flex align-items-center">
                    <span class="title"> Date Limite: </span>
                    <flat-pickr
                      :config="flatPickrConfig"
                      v-model="invoiceData.due_date"
                      class="form-control invoice-edit-input"
                      placeholder="Entrer la date limite"
                    />
                  </div>
                </div>
              </div>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing" />

            <!-- Invoice Client & Payment Details -->
            <b-card-body class="invoice-padding pt-0">
              <b-row class="invoice-spacing">
                <!-- Col: Invoice To -->
                <b-col cols="12" xl="6" class="mb-lg-1">
                  <h6 class="mb-2">Facture à:</h6>

                  <!-- Selected Client -->
                  <div v-if="invoiceData.client" class="mt-1">
                    <h6 class="mb-25">
                      {{ invoiceData.client }}
                    </h6>
                    <!-- <b-card-text class="mb-25">
                      {{ invoiceData.client.company }}
                    </b-card-text>
                    <b-card-text class="mb-25">
                      {{ invoiceData.client.address }}, {{ invoiceData.client.country }}
                    </b-card-text>
                    <b-card-text class="mb-25">
                      {{ invoiceData.client.contact }}
                    </b-card-text>
                    <b-card-text class="mb-0">
                      {{ invoiceData.client.companyEmail }}
                    </b-card-text> -->
                  </div>
                </b-col>

                <!-- Col: Payment Details -->
                <b-col
                  xl="6"
                  cols="12"
                  class="mt-xl-0 mt-2 justify-content-end d-xl-flex d-block"
                >
                  <div>
                    <h6 class="mb-2">Details de Payment:</h6>
                    <table>
                      <tbody>
                        <tr>
                          <td class="pr-1">Total Dû:</td>
                          <td>
                            <span class="font-weight-bold">{{
                              amountToCurrency(
                                defaultInvoiceInputs.total_pretax
                              )
                            }}</span>
                          </td>
                        </tr>
                        <tr>
                          <td class="pr-1">Mode de Paiement:</td>
                          <td>{{ defaultInvoiceInputs.account_name }}</td>
                        </tr>
                        <!-- <tr>
                          <td class="pr-1">
                            Country:
                          </td>
                          <td>United States</td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            IBAN:
                          </td>
                          <td>ETD95476213874685</td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            SWIFT code:
                          </td>
                          <td>BR91905</td>
                        </tr> -->
                      </tbody>
                    </table>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Items Section -->
            <b-card-body class="invoice-padding form-item-section">
              <div
                ref="form"
                class="repeater-form"
                :style="{ height: trHeight }"
              >
                <b-row
                  v-for="(item, index) in invoiceData.items"
                  :key="index"
                  ref="row"
                  class="pb-2"
                >
                  <!-- Item Form -->
                  <!-- ? This will be in loop => So consider below markup for single item -->
                  <b-col cols="12">
                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <div class="d-none d-lg-flex">
                      <b-row class="flex-grow-1 px-1">
                        <!-- Single Item Form Headers -->
                        <b-col cols="12" lg="5"> Service </b-col>
                        <b-col cols="12" lg="3"> Coût </b-col>
                        <b-col cols="12" lg="2"> Qté </b-col>
                        <b-col cols="12" lg="2"> Prix </b-col>
                      </b-row>
                      <div class="form-item-action-col" />
                    </div>

                    <!-- Form Input Fields OR content inside bordered area  -->
                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <div class="d-flex border rounded">
                      <b-row class="flex-grow-1 p-2">
                        <!-- Single Item Form Headers -->
                        <b-col cols="12" lg="5">
                          <label class="d-inline d-lg-none">Service</label>
                          <v-select
                            v-model="item.itemTitle"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="itemsOptions"
                            label="designation"
                            :clearable="false"
                            class="mb-2 item-selector-title"
                            placeholder="Sélectionner un service"
                            @input="(val) => updateItemForm(index, val)"
                          />
                        </b-col>
                        <b-col cols="12" lg="3">
                          <label class="d-inline d-lg-none">Coût</label>
                          <b-form-input
                            v-model="item.cost"
                            type="number"
                            class="mb-2"
                          />
                        </b-col>
                        <b-col cols="12" lg="2">
                          <label class="d-inline d-lg-none">Qté</label>
                          <b-form-input
                            v-model="item.qty"
                            type="number"
                            class="mb-2"
                          />
                        </b-col>
                        <b-col cols="12" lg="2">
                          <label class="d-inline d-lg-none">Prix</label>
                          <p class="mb-1">
                            {{ amountToCurrency(item.cost * item.qty) }}
                          </p>
                        </b-col>
                        <b-col cols="12" lg="5">
                          <label class="d-inline d-lg-none">Description</label>
                          <b-form-textarea
                            v-model="item.description"
                            class="mb-2 mb-lg-0"
                          />
                        </b-col>
                      </b-row>
                      <div
                        class="d-flex flex-column justify-content-between border-left py-50 px-25"
                      >
                        <feather-icon
                          size="16"
                          icon="XIcon"
                          class="cursor-pointer"
                          @click="removeItem(index)"
                        />
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="primary"
                @click="addNewItemInItemForm"
                :disabled="itemsOptions.length == 0"
              >
                Ajouter Service
              </b-button>
            </b-card-body>

            <!-- Invoice Description: Total -->
            <b-card-body class="invoice-padding pb-0">
              <b-row>
                <!-- Col: Sales Persion -->
                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-0 mt-3 d-flex align-items-center"
                  order="2"
                  order-md="1"
                >
                  <label
                    for="invoice-data-sales-person"
                    class="text-nowrap mr-50"
                    >Émetteur de la Facture:</label
                  >
                  <b-form-input
                    id="invoice-data-sales-person"
                    v-model="invoiceData.sales_person"
                    placeholder="Edward Crowley"
                  />
                </b-col>

                <!-- Col: Total -->
                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-6 d-flex justify-content-end"
                  order="1"
                  order-md="2"
                >
                  <div class="invoice-total-wrapper">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">Hors Taxe:</p>
                      <p class="invoice-total-amount">
                        {{ preTaxes }}
                      </p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">Taxe:</p>
                      <p class="invoice-total-amount">------</p>
                    </div>
                    <hr class="my-50" />
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">Total:</p>
                      <p class="invoice-total-amount">
                        {{ total }}
                      </p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing" />

            <!-- Note -->
            <b-card-body class="invoice-padding pt-0">
              <span class="font-weight-bold">Note: </span>
              <b-form-textarea v-model="invoiceData.note" />
            </b-card-body>
          </b-card>
        </b-form>
      </b-col>

      <!-- Right Col: Card -->
      <b-col cols="12" md="4" xl="3" class="invoice-actions">
        <!-- Action Buttons -->
        <b-card>
          <b-button
            @click="
              $router.push({
                name: 'apps-invoice-preview',
                params: { id: $route.params.id },
              })
            "
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-danger"
            class="mb-75"
            block
          >
            Annuler
          </b-button>

          <!-- Button: Print -->
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            block
          >
            Mettre à jour
          </b-button>
        </b-card>

        <!-- Payment Method -->
        <div class="mt-2">
          <b-form-group
            label="Accepter le Paiement Via"
            label-for="payment-method"
          >
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="paymentMethods"
              label="name"
              input-id="payment-method"
              class="payment-selector"
              @input="setPaymentMethod"
              :clearable="false"
            />
          </b-form-group>
        </div>
      </b-col>
    </b-row>

    <!-- <invoice-sidebar-send-invoice />
    <invoice-sidebar-add-payment /> -->
  </section>
</template>

<script>
/* eslint-disable */
import Logo from "@core/layouts/components/Logo.vue";
import { ref, computed, onMounted } from "@vue/composition-api";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import router from "@/router";
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BFormCheckbox,
  BPopover,
  BAlert,
  BLink,
  VBToggle,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { French } from "flatpickr/dist/l10n/fr.js";
import InvoiceSidebarSendInvoice from "../InvoiceSidebarSendInvoice.vue";
import InvoiceSidebarAddPayment from "../InvoiceSidebarAddPayment.vue";
import InvoiceDetails from "../InvoiceDetails.vue";

import useInvoicesList from "../invoice-list/useInvoiceList";
import { amountToCurrency } from "@/utils/formatAmount";

const requestOptions = {
  headers: {
    Accept: "application/vnd.api+json",
    "Content-Type": "application/vnd.api+json",
  },
};

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    BAlert,
    BLink,
    flatPickr,
    vSelect,
    Logo,
    InvoiceSidebarSendInvoice,
    InvoiceSidebarAddPayment,

    InvoiceDetails,
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  mixins: [heightTransition],

  // Reset Tr Height if data changes
  watch: {
    // eslint-disable-next-line func-names
    "invoiceData.items": function () {
      this.initTrHeight();
    },
  },
  mounted() {
    this.initTrHeight();
  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {
    addNewItemInItemForm() {
      this.$refs.form.style.overflow = "hidden";
      this.invoiceData.items.push(
        JSON.parse(JSON.stringify(this.itemFormBlankItem))
      );

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
        setTimeout(() => {
          this.$refs.form.style.overflow = null;
        }, 350);
      });
    },
    removeItem(index) {
      // reassign the item to the available services list
      const selectedItem = this.invoiceData.items[index];
      if (selectedItem)
        this.$store.commit(
          "invoices/UPDATE_ITEM_FROM_AVAILABLE_SERVICES",
          selectedItem.uid
        );

      this.invoiceData.items.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form ? this.$refs.form.scrollHeight : 0);
      });
    },
  },
  setup() {
    // config flatpickr
    const flatPickrConfig = ref({
      wrap: true,
      altFormat: "d-m-Y",
      altInput: true,
      dateFormat: "Y-m-d",
      locale: French,
    });
    const invoiceParams = ["payment_methods"];

    const defaultInvoiceInputs = ref({
      total_pretax: 0,
      account_name: "----------",
      account_number: "----------",
      account_country: "----------",
      account_currency: "----------",
      swift_code: null,
    });

    const itemFormBlankItem = {
      designation: "",
      cost: 0,
      qty: 0,
      description: "",
    };

    const invoiceData = ref({
      uid: null,
      client: null,
      project: null,
      issued_date: null,
      due_date: null,

      // ? Set single Item in form for adding data
      items: [JSON.parse(JSON.stringify(itemFormBlankItem))],

      sales_person: "",
      note: "",
      payment_method: null,
    });

    const preTaxes = ref(0);
    const total = ref(0);

    const { fetchProjectBudget, fetchInvoiceProperties, isLoading } =
      useInvoicesList();

    const getInvoiceData = () => {
      store
        .dispatch("invoices/get", { id: router.currentRoute.params.id })
        .then(async (invoice) => {
          const projectUID = invoice?.project.uid;
          await fetchProjectBudget(projectUID);

          return invoice;
        })
        .then((invoice) => {
          const {
            uid,
            facture_no,
            due_date,
            issued_date,
            net_payable,
            sales_person,
            note,
            client,
            items,
            payment_method,
            project,
          } = invoice;

          // set invoice data
          invoiceData.value.uid = uid;
          invoiceData.value.facture_no = facture_no;
          invoiceData.value.due_date = due_date;
          invoiceData.value.issued_date = issued_date;
          invoiceData.value.sales_person = sales_person;
          invoiceData.value.note = note;

          // set pretax
          defaultInvoiceInputs.value.total_pretax = net_payable;

          // set client
          invoiceData.value.client = client.name;

          // set project
          invoiceData.value.project = project.uid;

          //set payment method
          setPaymentMethod(payment_method);

          return items;
        })
        .then((services) => {
          services.forEach(async (service, index) => {
            const currService = itemsOptions.value.find(
              (s) => s.designation == service.designation
            );
            if (currService != undefined) {
              invoiceData.value.items[index].itemTitle = currService;
              await updateItemForm(index, currService);
              invoiceData.value.items[index].description = service.description;
            }
          });
        })
        .then(() => {
          updateTrackData();
        })
        .catch((error) => {
          invoiceData.value = undefined;
        });
    };

    onMounted(async () => {
      fetchInvoiceProperties(invoiceParams);
      getInvoiceData();
    });

    // Project budget services
    const itemsOptions = computed(() => {
      return store.getters["invoices/availableServices"];
    });

    const updateItemForm = async (index, val) => {
      const {
        cost_rate,
        qty,
        profitability,
        duty_free,
        tax_value,
        uid,
        designation,
        description,
      } = val;

      const cr = checkNumber(cost_rate);
      const quantity = checkNumber(qty);
      const profit = checkNumber(profitability);
      const pre_tax = checkNumber(duty_free);
      const tax = checkNumber(tax_value);

      invoiceData.value.items[index].uid = uid;
      invoiceData.value.items[index].designation = designation;
      invoiceData.value.items[index].cost = parseFloat(cr) * parseFloat(profit);
      invoiceData.value.items[index].qty = quantity;
      invoiceData.value.items[index].pre_tax = pre_tax;
      invoiceData.value.items[index].tax = tax;
      invoiceData.value.items[index].description = description;

      await updateTrackData();
      store.commit("invoices/REMOVE_ITEM_FROM_AVAILABLE_SERVICES", uid);
    };

    const updateTrackData = async () => {
      const services = invoiceData.value.items;

      // 1-/ Add pretax to total pretaxes if user enter pretax (HT) on single service
      preTaxes.value = services.reduce((acc, curr) => {
        return acc + parseFloat(curr.pre_tax);
      }, 0);

      total.value = services.reduce((acc, curr) => {
        return (
          acc + curr.pre_tax * (curr.tax_value != null ? curr.tax_value : 1)
        );
      }, 0);

      // Add tax on total taxes if user choose tax on a single service
      // if (attr == "tax") {
      //   let selectedTaxes = []; // use this array to get unique taxe slug

      //   taxes.value = services.reduce((acc, curr) => {
      //     if (!selectedTaxes.includes(curr.tax)) {
      //       let currTax = findTax(curr.tax);

      //       if (currTax != undefined) {
      //         selectedTaxes.push(currTax.slug);
      //         return acc + parseFloat(currTax?.value);
      //       }
      //       return acc;
      //     }
      //     return acc;
      //   }, 0);
      // }
    };

    const checkNumber = (num, rtnVal) => {
      if (!rtnVal) rtnVal = 0;
      if (num !== null && num !== "" && num !== undefined) return num;
      return rtnVal;
    };

    // Get payment methoods list from store
    const paymentMethods = computed(() => {
      return store.getters["invoices/paymentMethods"];
    });

    const setPaymentMethod = (pm) => {
      invoiceData.value.payment_method = pm.uid; // set payment method in invoice data

      defaultInvoiceInputs.value.account_name = pm.name;
      defaultInvoiceInputs.value.account_number =
        pm.account_details?.account_number;
      defaultInvoiceInputs.value.account_country =
        pm.account_details?.account_country;
      defaultInvoiceInputs.value.account_currency =
        pm.account_details?.account_currency;
      defaultInvoiceInputs.value.swift_code = pm.account_details?.swift_code;
    };

    const updateInvoice = () => {
      isSubmitting.value = true;
      return axios
        .put(
          `/finances/invoices/${invoiceData.value.uid}`,
          invoiceData.value,
          requestOptions
        )
        .then((response) => {
          isSubmitting.value = false;
          router.go(-1);
          toast({
            component: ToastificationContent,
            props: {
              title: "La facture a été modifié avec succès",
              icon: "CopyIcon",
              variant: "success",
            },
          });
          return response;
        })
        .catch((error) => {
          isSubmitting.value = false;
          toast({
            component: ToastificationContent,
            props: {
              title: "Une erreur est survenue lors de la modification du vendu",
              icon: "CopyIcon",
              variant: "danger",
            },
          });
          return error;
        });
    };

    return {
      invoiceData,
      itemsOptions,
      defaultInvoiceInputs,
      updateItemForm,
      itemFormBlankItem,
      paymentMethods,
      flatPickrConfig,
      preTaxes,
      total,

      amountToCurrency,
      setPaymentMethod,
      updateInvoice,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
